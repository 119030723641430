/*--------chat-ui--------------------------*/

.flex-1 {
    flex: 1;
}

.message-list {
    min-width: 140px;
    min-height:70vh;
}

.chat-list {
    min-width: 70px;
    width: 70px;
    border-right: 1px solid lightgray;
}
.bg-green {
     background-color: rgb(147, 242, 6);
}


.chatboard {
    display: grid;
    width: 100%;
    grid-gap: 0.6rem;
    max-width: 40rem;
  }
  
  .sent,
  .received {
    padding: 1%;
    min-width: 20rem;
    border-radius: 0.6rem;
  }
  
  .received {
    justify-self: left;
    background-color: #0A2472;
    color: white;
  }
  
  .sent {
    text-align: right;
    justify-self: right;
    align-content: right;
    background-color: #A6E1FA;
  }

label {
    font-weight: 350;
}

@media (min-width: 576px) {
    .chat-list {
        width: 100px;
    }
}

@media (min-width: 768px) {
    .chat-list {
        width: 160px;
    }
}

@media (min-width: 992px) {
    .chat-list {
        width: 200px;
    }
}


.w-40px {
    width: 40px;
}

.h-40px {
    height: 40px;
}

.w-50px {
    width: 50px;
}

.h-50px {
    height: 50px;
}

.flex-1 {
    flex: 1;
}

.bg-light-blue {
    background: #d0dfeb;
}

.white-space-nowrap {
    white-space: nowrap;
}

.text-overflow-ellipsis {
    text-overflow: ellipsis;
}

.fs-100rem {
    font-size: 1rem;
}

.fs-80rem {
    font-size: 0.8rem;
}

.cursor-pointer {
    cursor: pointer;
}

.ezychat-message-bubble--container {
    border-radius: 10px;
}

.header-height {
    box-sizing: border-box;
    height:46px !important;
    border-bottom:0.5px dotted #C4261D;
}

.eparts-row {
    display:flex;
    flex-wrap: wrap;
}
.eparts-input {
    border: 1px solid #C4261D;
    padding:10px 15px;
    background-color:#fff;
    border-radius: 0.25rem !important;
    box-shadow: none !important;
}

.eparts-row .btn-sm {
    padding:8px 12px !important;
    font-size:14px;
}


.eparts-td,.eparts-row td {
     padding:4px 6px !important;
     font-size:0.95rem;
     font-weight:300;
}
.eparts-form-group {

    width:520px;
}
.column-modal .modal-dialog  {
    /* min-width:80%; */
    max-width:600px;
    padding:0
}

.column-modal .modal-content {
     height:40%;
     min-width:90%;
}
.search-input {
    width:70% !important;
}

@media (max-width: 576px) {
    .eparts-hide {
       display:none;
    }
}

@media (max-width: 575.98px) {
    .hidden-xs {
       display:none;
    }
 
    .form-group {
       width:95%;
    }
   
    .form-group-xs {
        margin-left:50px;
    }
}



/*-------------end of chat-ui --------------*/