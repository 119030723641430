.countdown-container {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin: 0.5rem;
  }
  
  .countdown-container > form {
    padding: 0.5rem;
  }
  
  .countdown-container > form > label {
    margin-right: 1rem;
  }
  
  .expired-notice {
    text-align: center;
    padding: 0.25rem;
    border: 1px solid #ebebeb;
    border-radius: 0.25rem;
    margin: 0.25rem;
    background-color: #e2e2e2;
    box-shadow: 2px 2px #cfc9c9;
  }
  
  .expired-notice > span {
    font-size: 1.2rem;
    font-weight: bold;
    color: red;
  }
  
  .expired-notice > p {
    font-size: 0.5rem;
  }
  
  .show-counter {
    padding: 0.5rem;
  }
  
  .show-counter .countdown-link {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 380;
    font-size: 1.25rem;
    line-height: 1rem;
    padding: 0.5rem;
    border: 3px solid #e2e2e2;
    border-radius: 0.25rem;
    text-decoration: none;
    color: #000;
    border-radius: 5px;
    /* background-color: #ececec; */
    /* box-shadow: 2px 2px #C4261D; */
  }
  
  .show-counter .countdown {
    line-height: 1.25rem;
    padding: 0 0.75rem 0 0.75rem;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  
  .show-counter .countdown.danger {
    color: #ff0000;
  }
  
  .show-counter .countdown > p {
    margin: 0;
  }
  
  .show-counter .countdown > span {
    text-transform: uppercase;
    font-size: 1.5rem;
    line-height: 2rem;
  }
  
  @media only screen and (max-width: 500px) {
    .show-counter .countdown > span {
      font-size: 1rem;
      line-height: 1rem;
    }
  
    .show-counter .countdown-link {
      font-size: 1.5rem;
    }
  }