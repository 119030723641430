body {
  /* margin: 0; */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/* body {
  position: relative;
  padding-bottom: 50px;
} */
.eparts {
  background:#C4261D !important;
  color:#fff !important;
}


.bg-login {

  background-image: url('./images/pallet.jpg');
    
}

.footer_old {
  position:absolute;
  bottom:0;
  width:100%;
  height:40px;   /* Height of the footer */
  /* background:#6cf; */
}
/*---it is correct type---*/
#footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height:40px;
  z-index: 999;
}

.nav-tabs .nav-item {
  margin-right:2px !important;
  /* font-weight: 800; */
  font-size:90%;
  /* border:1px solid #C4261D; */
}
/* .pull-right {
   position:absolute;
   right:10px;
} */
.btn-label {
  max-width:140px !important;
  font-size:"50%";
  padding:6px;
}
.nav-tabs .nav-link.active ,
.nav-tabs .nav-item.show .nav-link {
  background:#C4261D !important;
  color:#fff !important;
  border-radius:3px;
}
.tab-content {
  padding:5px 5px 30px 2px !important;
}
.border-eparts {
  border-color:#C4261D !important;
}
.page-item.active .page-link  {
  background:#C4261D !important;
  color:#fff !important;
}

.page-item.active .page-link-warning {
  background:#f0ad4e !important;
  color:#fff !important;
}


#pageDropDown {
  background:#C4261D !important;
  color:#fff !important;
  padding:6px 8px !important;
}
.editable-input {
  border:1px solid #C4261D !important;
  background-color:#fff !important;
}

.brand-row:before {
   display:block;
   width:100%;
   content:'';
   border:0.5px solid #e2e2e2;
   margin-Bottom:50px
}

.no_selection {
  user-select: none; /* standard syntax */
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
}
.todoForm button {
  width:86px !important;
  padding:3px !important;
  height:42px !important;
}
.epartsHeader {
   border-bottom:1px solid #e2e2e2 !important;
  
}
.navbar-brand {
  color:#C4261D !important;
}
.epartsFooter {
   border-top:1px solid #e2e2e2 !important;
   color:#C4261D;
}

/* .react-bootstrap-table {
   margin:50px 4px !important;
} */
/* .dwg-modal {
  min-width:90%;
} */
.modal-template .modal-dialog  {
  min-width:80%;
  padding:0
}
.modal-template .modal-content {
   height:80%;
   min-width:90%;
}

/* .modal-dialog {
  min-width:50%;
  padding:0 !important;
} */
/* .modal-content {
  height:80%;
  min-width:40%;
  width:80%;
} */
.dwg-body {
  min-height:75vh;
}
.dwg-label {
   min-height:30vh;
}
.label-embed {
  width:100%;
  height:100%;
  min-height:450px;
}
.dwg-embed {
  width:100%;
  height:100%;
  min-height:748px;
}

#text-filter-column-ref_no {
  border:1px solid #C4261D !important;
  border-radius:5px;
  min-width:180px;
}
.react-bootstrap-table-editing-cell input {
  border:1px solid #C4261D !important;
  border-radius:5px;
  color:'red';
}

.react-bootstrap-table td {
   font-weight:420 !important;
}

.debug-print td, .debug-print th {
   padding-top:8px;
   padding-bottom:8px;
   padding-left:14px;
   padding-right:14px;

}
.pagination .btn-default {
   /* color:red !important; */
   border:0.3px solid #C4261D !important;
   /* font-size:105%; */
}

.nav-item .badge {
    padding:2px !important;
}
.double-line {
  font-size:110%;
  text-decoration-line: underline;
  text-decoration-style: double;
  text-underline-offset: 8px;
}
.smallCard {
    display:none;
}
@media (max-width: 767.98px) {
  .slogan,.hideLogout {
    display:none;
  }
  .lgTable {
     display:none;
  }
  .smallCard {
     display:block;
  }

  .brand-line {
     display:none;
  }
  .mx-w-70 {
    min-width: 70% !important;
  }
  .btn-label {
     margin:10px 2px !important;
  }
  .col-xs-2 {
    margin-bottom:8px;
  }
  .modal-dialog {
    min-width:90%;
    padding:0 !important;
  }
  .react-bootstrap-table th:nth-child(4) {
     /* color:red !important; */
     color: #f7f7f9 !important; /* same as input */
     padding:0.5em !important;
     /* display:none; */
  }
  .hideColumn {
    display:none;
  }
  .modal-content {
    width:100%;
  }
  /* #text-filter-column-ref_no {
    width:180px;
  } */
}
